<template>
  <div>
    <el-form :inline="true" :model="batchObj" class="batch-ipt">
      <el-form-item>
        <el-input v-model="batchObj.code" size="small" placeholder="条码" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="batchObj.module_no" size="small" placeholder="型号" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="batchIpt">批量填充</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  data() {
    return {
      batchObj:{module_no:null,code:null}
    };
  },
  async created() {},
  methods: {
    batchIpt(){
      if(this.batchObj.module_no||this.batchObj.code){
        this.$emit("onSubmit", cloneDeep(this.batchObj));
        this.batchObj.module_no=null;
        this.batchObj.code=null;
      }
    }
  }
};
</script>
<style scoped>
.batch-ipt{
  text-align: right;
}
</style>